

import ajax from "./ajax";

// const BASE = 'http://54.252.223.65:8083'
const BASE = '/api'

export const getLogs = () => ajax(BASE + "/log/")

export const getOrderLogs = () => ajax(BASE + "/orderlog/")

export const getTWOrderLogs = () => ajax(BASE + "/tworderlog/")

export const getBUOrderLogs = () => ajax(BASE + "/buningorderlog/")

export const getTBSOrderLogs = () => ajax(BASE + "/tbsorderlog/")

export const getFFOrderLogs = () => ajax(BASE + "/fforderlog/")

export const reqLogin = (username, password) => ajax(BASE + '/login/', {username,password},"POST")

export const reqForget = (email) => ajax(BASE + '/forget/', {email},"POST")

export const reqADDUser = (user) => ajax(BASE + '/manage/user/add', user,"POST")

export const reqAddProduct = (sku,z_id,shopify_id) => ajax(BASE + '/addproduct', {sku,z_id,shopify_id},"POST")


