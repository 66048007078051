import React, { Component } from "react";
import {withRouter} from 'react-router-dom'
import {Modal} from 'antd'

import "./index.less";
import LinkButton from "../link-button";
import {formatDate} from"../../utils/dateUtils"
import memoryUtils from"../../utils/memoryUtils"
import storageUtils from"../../utils/storageUtils"
import menuList from "../../config/menuConfig"
/* 左侧导航组件 */


class Header extends Component {
  state = {
    currentTime: formatDate(Date.now()),
  }

  getTime = () =>{
    this.intervalid = setInterval(()=>{
      const currentTime = formatDate(Date.now())
      this.setState({currentTime})
    },1000)
  }

  getTitle = () => {

    const path = this.props.location.pathname
    let title
    menuList.forEach(item => {
      if(item.key === path){
        title = item.title
      }else if(item.children){
        const cItem = item.children.find(cItem => cItem.key === path)
        if(cItem){
          title = cItem.title
        }
      }
    })
    return title

  }

  logout = ()=>{
    Modal.confirm({
      content: 'Are you sure ?',
      onOk:()=>{
        
        storageUtils.removeUser()
        memoryUtils.user={}

        this.props.history.replace('/login')
      }

    })
  }

  /*
    第一次render（）之后执行 一次
    一般在此执行异步操作： 发ajax请求、启动定时器
    */
  componentDidMount(){
    this.getTime()
  }

  /*
     当前主键卸载之前调用
    */
  componentWillUnmount(){

    //清除定时器
    clearInterval(this.intervalid)

  }

  render() {

    const {currentTime} = this.state
    const username = memoryUtils.user.username
    const title = this.getTitle()

    return (
      <div className="header">
        <div className="header-top">
          <span>Welcome, {username}</span>
          <LinkButton onClick={this.logout}>Sign Out</LinkButton>
        </div>
        <div className="header-bottom">
          <div className="header-bottom-left">{title}</div>
          <div className="header-bottom-right">
            <span>{currentTime}</span>

          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(Header)
