const menuList = [


  {
    title: 'Zanui Orders Script',
    key: '/order',
    icon: 'windows',
    isPublic: true
  },

  {
    title: 'T & W Orders Script',
    key: '/tworder',
    icon: 'windows'
  },

  {
    title: 'Bunnings Orders Script',
    key: '/buningsorder',
    icon: 'windows'
  },

  {
    title: 'Fantastic Furniture Orders Script',
    key: '/fforder',
    icon: 'windows'
  },

  {
    title: 'The Block Shop Orders Script',
    key: '/tbsorder',
    icon: 'windows'
  },

 
]

export default menuList