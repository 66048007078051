import React, { Component } from "react";
import { Link,withRouter} from "react-router-dom";
import { Menu, Layout, Icon } from "antd";

import menuList from "../../config/menuConfig";

import "./index.less";
import logo from "../../assets/images/logo.png";
const { Sider } = Layout;
const { SubMenu } = Menu;

/* 左侧导航组件 */

 class LeftNav extends Component {
  getMenuNodes_map = (menuList) => {
    return menuList.map((item) => {
      if (!item.children) {
        return (
          <Menu.Item key={item.key}>
            <Link to={item.key}>
              <Icon type={item.icon} />
              <span>{item.title}</span>
            </Link>
          </Menu.Item>
        );
      } else {
        return (
          <SubMenu
            key={item.key}
            title={
              <span>
                <Icon type={item.icon} />
                <span>{item.title}</span>
              </span>
            }
          >
            {this.getMenuNodes(item.children)}
          </SubMenu>
        );
      }
    });
  };

  getMenuNodes = (menuList) => {
    const path = this.props.location.pathname
    return menuList.reduce((pre, item) => {
      //向pre添加<Menu.Item>
      if (!item.children) {
        pre.push(
          <Menu.Item key={item.key}>
            <Link to={item.key}>
              <Icon type={item.icon} />
              <span>{item.title}</span>
            </Link>
          </Menu.Item>
        );
      } else {

        const cItem = item.children.find(cItem => 
          path.indexOf(cItem.key) === 0)
        
        if(cItem){
          this.openKey = item.key
        }

        //向pre添加<SubMenu>

        pre.push(
          <SubMenu
            key={item.key}
            title={
              <span>
                <Icon type={item.icon} />
                <span>{item.title}</span>
              </span>
            }
          >
            {this.getMenuNodes(item.children)}
          </SubMenu>
        );
      }

      return pre;
    }, []);
  };

  state = {
    collapsed: false,
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  onCollapse = (collapsed) => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  componentWillMount(){
    this.menuNodes = this.getMenuNodes(menuList)
  }

  render() {
    const { collapsed } = this.state;
    let path = this.props.location.pathname

    const openKey = this.openKey
    console.log(openKey)

    if(path.indexOf('/product') === 0){
      path = '/product'
    }

    return (
      <div className="left-nav">
        <Link to="/" className="left-nav-header">
          <img src={logo} alt="Logo" />
          <h1>inDemand</h1>
        </Link>
        <Layout style={{ minHeight: "100%" }}>
          <Sider width="340" collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
            <Menu theme="dark" selectedKeys={[path]} defaultOpenKeys={[openKey]} mode="inline">

              {/* <Menu.Item key="/home" icon={<PieChartOutlined />}>
                <Link to="/home">
                  <span>Home</span>
                </Link>
              </Menu.Item>

              <SubMenu key="sub1" icon={<UserOutlined />} title="Products">
                <Menu.Item key="/category" icon={<UserOutlined />}>
                  <Link to="/category">
                    <span>Product Types</span>
                  </Link>


                </Menu.Item>
                <Menu.Item key="/product" icon={<UserOutlined />}>
                  <Link to="/product">
                    <span>Product Management</span>
                  </Link>
                </Menu.Item>


              </SubMenu>

              <Menu.Item key="/user" icon={<PieChartOutlined />}>
                <Link to="/user">
                  <span>User</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="/role" icon={<PieChartOutlined />}>
                <Link to="/role">
                  <span>Role</span>
                </Link>
              </Menu.Item> */}

              {this.menuNodes}
            </Menu>
          </Sider>
        </Layout>
      </div>
    );
  }
}

export default withRouter(LeftNav)