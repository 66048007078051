
import React, { Component } from "react";
import { Card, Table} from "antd";

import LinkButton from "../../components/link-button";
import { getOrderLogs } from "../../api";
import './order.less'



export default class Order extends Component{
    state = {
        loading: false,
        order_list: "",
        server_down:"",
        showStatus: 0, // 0 show null, 1 show add, 2 show update
      };
    
      initColumns = () => {

        this.columns = [
          {
            title: "Status",
            dataIndex: "Status",
            key: "Status",            
          },
          {
            title: "Time",
            dataIndex: "Time",
            key: "Time",
            width: '20%'
          },
          {
            title: "Logs",
            dataIndex: "Logs",
            key: "Logs",
            ellipsis: true,
            className: "cap"
          },
          {
            title: "Type",
            dataIndex: "Type",
            key: "Type",
            width: '12%'
          },


        ];
      };
    
      showUpdate = (productlist) => {
        this.product = productlist  
    
        this.setState({
            showStatus: 2,
          });
    
      };
      showAdd = () => {
        this.setState({
          showStatus: 1,
        });
      };
    
      getOrders = async () => {
        this.setState({ loading: true });

        const result = await getOrderLogs();
    
        this.setState({ loading: false });
        console.log(result)
       
    
        const order_list = result.data.results;

        const checktime = Date.parse(order_list[0].Time)

        const nowtime = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')       
        
        let fourhago = Date.parse(nowtime)-14400

        let ress = fourhago > checktime ? "alertneed": " no worries"
        
        this.setState({server_down:ress})


        if (result.status === 0) {
          this.setState({ order_list });
        }
      };
    
      // 为第一次render（）准备数据
      componentWillMount() {
        this.initColumns();
      }
    
      // 执行异步任务： 发异步ajax请求
      componentDidMount() {
        this.getOrders();
        
        
      }


    
      render() {
        const { loading, order_list, showStatus, server_down } = this.state;

        const iccon = (<span className="iccon">X</span>)
        
        let title = ""

        title = (
          <span>
            <LinkButton>Order Script Result        
            </LinkButton>
          </span>
        );
       

    

    
        return (
          <Card title={title} >
            <Table
              bordered
              rowKey="Status"
              loading={loading}
              dataSource={order_list}
              rowClassName={(record, index) => (record.Status === "Failed" ? "red" : "green")}
              columns={this.columns}
              pagination={{ defaultPageSize: 50, showQuickJumper: true }}
            /> 
          </Card>
        );
      }
}
