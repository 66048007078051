/* 入口 js */

import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import memoryUtils from "./utils/memoryUtils"
import storageUtils from "./utils/storageUtils"

// 将app组件渲染到index.html root div shang
const user = storageUtils.getUser()
memoryUtils.user = user

ReactDOM.render(<App />, document.getElementById('root'))