import React, {Component} from 'react'
//import {message, Button} from 'antd'
import {BrowserRouter,Route,Switch} from 'react-router-dom'

import Login from './pages/login/login'
import Admin from './pages/admin/admin'
import Forget from './pages/login/forgetlogin'
/*
应用的根主键
*/
export default class App extends Component{
    // handleClick = () =>{
    //     message.success("this is successful message....")
    // }
render(){
    // return <Button type="primary" onClick={this.handleClick}>Primary</Button>
        return(
            <BrowserRouter>
            <Switch> {/*只选其中一个路由 */} 
            <Route path="/login" component={Login}></Route>
            <Route path="/forget" component={Forget}></Route>
            <Route path="/" component={Admin}></Route>
            
            </Switch>
            </BrowserRouter>
        )
    }
}