import React, {Component} from 'react'
import './index.less'

// export default class xxx extends Component{
//     render(){
//         return(
//             <div>
//                 xxx
//             </div>
//         )
//     }
// }


/*
    外形像链接的按钮
*/

export default function LinkButton(props){
    return <button {...props} className="link-button"></button>
}