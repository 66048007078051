import React, {Component} from 'react'
import './home.less'

export default class Home extends Component{
    render(){
        return(
            <div className="home">
                Welcome to inDemand backend listing system
            </div>
        )
    }
}
