/*
能发送异步ajax请求的函数模块
封装axios库
函数的返回值是promise对象
1. 优化request error异常
*/


import axios from 'axios'
import {message} from 'antd'

export default function ajax(url, data = {}, type = "GET") {

    // url = "http://54.252.223.65:8082"+url
    return new Promise((resolve, reject) => {
        let promise
        // 1. 执行异步ajax请求

        if (type === 'GET') {
            promise = axios.get(url, {
                params: data
            })
        } else {
            
            promise = axios.post(url, data)
        }


        // 2. 如果成功 调用 resolve（value)

        promise.then(value => {
            resolve(value.data)

        }).catch(error => {
            message.error("Yannnn!!! What is Wrong with your API, No Working yet ???"+ error.message)

        })


        //3. 如果失败 通常用有rejcet 但这里要要用提示信息
    })


}

// 请求登录接口

// ajax('/login',{username:'Tom',password:'12345'},POST).then()

// // 添加用户

// ajax('/manage/user/add',{username:'Tom',password:'12345',phone:'123412342'},POST)