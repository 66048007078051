import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {
  Form,
  Icon,
  Input,
  Button,
  message,
  Spin, 
  Alert
} from 'antd'
import './login.less'
import logo from '../../assets/images/logo.png'
import {reqForget} from '../../api'


const Item = Form.Item // 不能写在import之前


/*
登陆的路由组件
 */
class Forget extends Component {
  state = {
    loading: false,
    iconLoading: false,
  };

  enterLoading = () => {
    this.setState({ loading: true });
  };

  enterIconLoading = () => {
    this.setState({ iconLoading: true });
  };

  stenterLoading = () => {
    this.setState({ loading: false });
  };

  stenterIconLoading = () => {
    this.setState({ iconLoading: false });
  };

  handleSubmit = (event) => {

    this.enterLoading()
    this.enterIconLoading()
    // 阻止事件的默认行为
    event.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      // 检验成功
      if (!err) {       
        // 请求登陆
        const {email} = values
        console.log('提交登陆的ajax请求', email)
        const result = await reqForget(email) // {status: 0, data: user}  {status: 1, msg: 'xxx'}
        console.log('请求成功', result)
        if (result.status===0) { // 登陆成功
          // 跳转到管理界面 (不需要再回退回到登陆)
          message.success("email sent ")
          this.props.history.replace('/login')
          this.stenterLoading()
          this.stenterIconLoading()

        } else { // 登陆失败
          // 提示错误信息
          if(result.status === 2){
            message.error("Wrong Email address...")
            this.stenterLoading()
            this.stenterIconLoading()
          }
          else{
            message.error("something wrong at our backend please contact inDemand")
            this.stenterLoading()
            this.stenterIconLoading()
          }

        }

      } else {
        console.log('something wrong!!!!')
        this.stenterLoading()
        this.stenterIconLoading()
      }
    });
   
  }

  

  render () {


    // 得到具强大功能的form对象
    const form = this.props.form
    const { getFieldDecorator } = form;

    return (
      <div className="login">
        <header className="login-header">
          <img src={logo} alt="logo"/>
          <h1>Forget your password</h1>
        </header>
        <section className="login-content">
          <h2>Get your password</h2>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Item>
              {
                getFieldDecorator('email', { // 配置对象: 属性名是特定的一些名称
                  // 声明式验证: 直接使用别人定义好的验证规则进行验证
                  rules: [
                  ]
                })(
                  <Input
                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="email"
                  />
                )
              }
            </Item>           
            <Item>
              <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
                Send to your email
              </Button>
            </Item>
          </Form>
        </section>
      </div>
    )
  }
}

/*
1. 高阶函数
    1). 一类特别的函数
        a. 接受函数类型的参数
        b. 返回值是函数
    2). 常见
        a. 定时器: setTimeout()/setInterval()
        b. Promise: Promise(() => {}) then(value => {}, reason => {})
        c. 数组遍历相关的方法: forEach()/filter()/map()/reduce()/find()/findIndex()
        d. 函数对象的bind()
        e. Form.create()() / getFieldDecorator()()
    3). 高阶函数更新动态, 更加具有扩展性

2. 高阶组件
    1). 本质就是一个函数
    2). 接收一个组件(被包装组件), 返回一个新的组件(包装组件), 包装组件会向被包装组件传入特定属性
    3). 作用: 扩展组件的功能
    4). 高阶组件也是高阶函数: 接收一个组件函数, 返回是一个新的组件函数
 */
/*
包装Form组件生成一个新的组件: Form(Login)
新组件会向Form组件传递一个强大的对象属性: form
 */
const WrapForget = Form.create()(Forget)
export default WrapForget
/*
1. 前台表单验证
2. 收集表单输入数据
 */

/*
async和await
1. 作用?
   简化promise对象的使用: 不用再使用then()来指定成功/失败的回调函数
   以同步编码(没有回调函数了)方式实现异步流程
2. 哪里写await?
    在返回promise的表达式左侧写await: 不想要promise, 想要promise异步执行的成功的value数据
3. 哪里写async?
    await所在函数(最近的)定义的左侧写async
 */