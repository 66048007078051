import React, {Component} from 'react'
import { Redirect, Route, Switch} from 'react-router-dom'
import { Layout } from 'antd';

import memoryUtils from "../../utils/memoryUtils"
import LeftNav from '../../components/left-nav'
import Header from '../../components/header'
import Home from '../home/home'


import Order from '../order/order'

import TWOrder from '../order/tworder'

import BUOrder from '../order/buningsorder'

import FFOrder from '../order/fforder'

import TBSOrder from '../order/theblockshoporder'





const {Footer, Sider, Content } = Layout;

export default class Admin extends Component{
    render(){
        let user = memoryUtils.user
        
        //如果没有 user
        console.log(user)
        if(Object.keys(user).length === 0){
          console.log(user)
            return <Redirect to ='/login'/>
        }
    

        return(
            <Layout style={{minHeight:'100%'}}>             
            <Sider width="340"><LeftNav/></Sider>
            <Layout>
              <Header />
              <Content>

                  <Switch>
                    <Route path='/home' component={Home} />
                    <Route path='/order' component={Order} />
                    <Route path='/tworder' component={TWOrder} />
                    <Route path='/buningsorder' component={BUOrder} />
                    <Route path='/fforder' component={FFOrder} /> 
                    <Route path='/tbsorder' component={TBSOrder} />                        
                    <Redirect to='/order' />
                  </Switch>

              </Content>
              <Footer style={{textAlign:'center',color:"#ccc"}}>inDemand</Footer>
            </Layout>
          </Layout>
        )
    }
}